import React from "react";
import Blog from "./components/Blog";
import Footer from "./components/Footer";
import Aux from "./hoc/Aux_";
import { Link } from "react-router-dom";

class HomeFive extends React.Component {
  render() {
    var bkg1 = {
      backgroundImage: "url(images/juke3.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center",
    };

    return (
      <Aux>
        
      <section className="section bg-home home-half" id="home" style={bkg1}>
      <div className="bg-overlay"></div>
      <div className="display-table">
          <div className="display-table-cell">
              <div className="container">
                  <div className="row vertical-content">
                      <div className="col-lg-7 text-white text-left margin-t-1">
                          <h1 className="home-title">Jukebucks Launcher</h1>
                          <p className="padding-t-15 home-desc home-subtitle-width-100">Enjoy your favorite music content customized best for you!</p>
                          <p>Powered by Bing</p>
                          <Link to={{ pathname: "https://play.google.com/store/apps/details?id=org.jukebucks.launcher" }} target="_blank" className="btn btn-custom margin-t-30 waves-effect waves-light">Download now </Link>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
        
        {/* Blog Component*/}
        <Blog />

        {/* Footer Component*/}
        <Footer />

      </Aux>
    );
  }
}

export default HomeFive;
