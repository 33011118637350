import React from 'react';
import ReactDOM from 'react-dom';
import HomePage from './HomeOne';
import LandingPage from './HomeFive';
import Terms from './TermsOfService'
import Privacy from './Privacy'
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

class Root extends React.Component {
  render() {
  	return(
  		<BrowserRouter basename={'/'} >
		  	<Switch>
			  <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomePage}/>
			  <Route path={`${process.env.PUBLIC_URL}/landing`} component={LandingPage}/>
			  <Route path={`${process.env.PUBLIC_URL}/terms`} component={Terms}/>
			  <Route path={`${process.env.PUBLIC_URL}/privacy`} component={Privacy}/>    
			</Switch>
		</BrowserRouter>
  	);
  }
 }

ReactDOM.render(<Root />, document.getElementById('root'));
registerServiceWorker();
