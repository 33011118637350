import React from "react";

class Services extends React.Component {
  render() {
    return (
      <section className="section pt-5" id="services">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1 className="section-title text-center">Our Services</h1>
              <div className="section-title-border margin-t-20" />
              <p className="section-subtitle text-muted text-center padding-t-30 font-secondary">
                We craft digital, graphic and dimensional thinking, to create
                music and art experiences that have meaning and add a
                value for our clients.
              </p>
            </div>
          </div>
          <div className="row margin-t-30">
            <div className="col-lg-4 margin-t-20">
              <div className="services-box text-center hover-effect">
                <i className="pe-7s-diamond text-custom" />
                <h4 className="padding-t-15">analyzing music industry</h4>
                <p className="padding-t-15 text-muted">
                    user preferences, data analyzing and trend learning
                </p>
              </div>
            </div>
            <div className="col-lg-4 margin-t-20">
              <div className="services-box text-center hover-effect">
                <i className="pe-7s-music text-custom" />
                <h4 className="padding-t-15">World of music</h4>
                <p className="padding-t-15 text-muted">
                  mobile launcher for experiencing music vividly
                </p>
              </div>
            </div>
            <div className="col-lg-4 margin-t-20">
              <div className="services-box text-center hover-effect">
                <i className="pe-7s-users text-custom" />
                <h4 className="padding-t-15">Connecting people</h4>
                <p className="padding-t-15 text-muted">
                  Shared intrests can now easily communicate
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Services;
