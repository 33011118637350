import React from 'react';

class Features extends React.Component {
  render() {
  	return (
        <section className="section bg-light" id="features">
        <div className="container">
            <div className="row vertical-content">
                <div className="col-lg-5">
                    <div className="features-box">
                        <h3>A digital web audio studio creating & engaging online experiences</h3>
                        <p className="text-muted web-desc">A profound approch to everything related to music</p>
                        <ul className="text-muted list-unstyled margin-t-30 features-item-list">
                            <li className="">learning music trends</li>
                            <li className="">integrating between multiple media provider</li>
                            <li className="">anaylizing data of tours and concert worldwide</li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="features-img features-right text-right">
                        <img src="images/online-world.svg" alt="" className="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </section>
  	);
  }
}
export default Features;