import React from 'react';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
  render() {
  	return (
         <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 margin-t-20">
                        <h4> Copyright © 2022 Jukebucks</h4>
                    </div>
                    <div className="col-lg-3 margin-t-20">
                        
                        <Link
                        to={{ pathname: "/terms" }} target="_blank"
                        className="col-lg-3 margin-t-20"
                      >
                        Terms of Service
                      </Link>
                    </div>
                    <div className="col-lg-3 margin-t-20">
                    <Link
                    to={{ pathname: "/privacy" }} target="_blank"
                    className="col-lg-3 margin-t-20"
                  >
                    Privacy
                  </Link>
                    </div>
                 
                </div>
            </div>
        </footer>
  	);
  }
}
export default Footer;