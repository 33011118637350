import React from "react";

class Blog extends React.Component {
  render() {
    return (
      <section className="section bg-light pt-5" id="blog">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1 className="section-title text-center">Features</h1>
              <div className="section-title-border margin-t-20" />
              <p className="section-subtitle text-muted text-center font-secondary padding-t-30">
                Jukebucks Launcher organizes your creativity, muze and media in
                one place
              </p>
            </div>
          </div>

          <div className="row margin-t-30">
            <div className="col-lg-4">
              <div className="blog-box margin-t-30 hover-effect">
                <img
                  src="images/listenDance3.jpg"
                  className="img-fluid"
                  alt=""
                />
                <div>
                  <h5 className="mt-4 text-muted"> Live your Music</h5>
                  <h4 className="mt-3">Connect and Share</h4>
                  <p className="text-muted">
                    Listen to personalized playlists & Easily share it on social
                    media
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="blog-box margin-t-30 hover-effect">
                <img src="images/news.jpg" className="img-fluid" alt="" />
                <div>
                  <h5 className="mt-4 text-muted">Tours, concerts and news</h5>
                  <h4 className="mt-3">Keep updated and organized</h4>
                  <p className="text-muted">
                    Manage your news feed, follow your favorite artists tours
                    and concerts
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="blog-box margin-t-30 hover-effect">
                <img src="images/studio.jpg" className="img-fluid" alt="" />
                <div>
                  <h5 className="mt-4 text-muted">Create your Tune</h5>
                  <h4 className="mt-3">expand your creativity</h4>
                  <p className="text-muted">
                    A complete studio in your pockect to enhance your creation
                    and art
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Blog;
