import React from 'react';
import Navbar from './components/Navbar';
import Services from './components/Services';
import Features from './components/Features';
import Started from './components/Started';
import Footer from './components/Footer';
import Aux from './hoc/Aux_';

class HomeOne extends React.Component {
  render() {
  
  	return (
        <Aux>
                {/* Navbar Component*/}
                <Navbar />

                <section className="section bg-home home-half" id="home" data-image-src="images/bg-home.jpg">
                    <div className="bg-overlay"></div>
                    <div className="display-table">
                        <div className="display-table-cell">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2 text-white text-center">
                                        <h1 className="home-title">Enjoy Music Easily</h1>
                                        <p className="padding-t-15 home-desc">The best user-friendly launcher specified for music</p> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-pattern-effect">
                        <img src="images/bg-pattern.png" alt="" className='img-bg'/>
                    </div>
                </section>

                {/* Services Component*/}
                <Services />

                {/* Features Component*/}
                <Features />

                {/* Started Component*/}
                <Started />
                
                {/* Footer Component*/}
                <Footer />
        </Aux>
  	);
  }
}

export default HomeOne;