import React from 'react';
import { Link } from 'react-router-dom';

class Started extends React.Component {
  render() {
  	return (
        <section className="section section-lg bg-get-start">
            <div className="bg-overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 text-center">
                        <h1 className="get-started-title text-white">Jukebucks launcher</h1>
                        <div className="section-title-border margin-t-20 bg-white"></div>
                        <p className="section-subtitle font-secondary text-white text-center padding-t-30">Improve your mobile phone for an amazing music and art experience </p>
                        <Link to={{ pathname: "/landing" }} target="_blank" className="btn btn-bg-white waves-effect margin-t-20 mb-4">Get Started <i className="mdi mdi-arrow-right"></i> </Link>
                    </div>
                </div>
                <div className="row">
                <div className="col-lg-8 offset-lg-2 text-center">
                    <h1 className="get-started-title text-white">Piezo Extension</h1>
                    <div className="section-title-border margin-t-20 bg-white"></div>
                    <p className="section-subtitle font-secondary text-white text-center padding-t-30">Easy to use Movie hub for reviews, news and More</p>
                    <Link to={{ pathname: "https://piezo.run/" }} target="_blank" className="btn btn-bg-white waves-effect margin-t-20 mb-4">Get Started <i className="mdi mdi-arrow-right"></i> </Link>
                </div>
            </div>
               
            </div>
            <div className="bg-pattern-effect">
                <img src="images/bg-pattern-light.png" alt="img-bg"/>
            </div>
        </section>
  	);
  }
}
export default Started;