import React from "react";
import Navbar from "./components/Navbar";
import Footer from './components/Footer';
import Aux from "./hoc/Aux_";

class Terms extends React.Component {
  render() {
    var bkg1 = {
      backgroundImage: "url(images/img-1.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center",
    };

    return (
      <Aux>
        <Navbar />
        <section
          className="section section-lg "
          id="home"
          style={bkg1}
        >
          <div className="bg-overlay" />
          <div className="display-table">
            <div className="display-table-cell">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 offset-lg-2 text-white text-center">
                    <h2 className=" font-bold text-2xl">Terms of Service</h2>
                    <p className="p-terms">
                      Please read these terms of service and license carefully
                      before installing or using our site/extension/product (the
                      “Services”). By installing our Services, you agree to be
                      bound by all terms and conditions of this agreement and
                      all policies and guidelines incorporated by reference.
                    </p>
                    <p className="p-terms">
                      Please read this agreement carefully. if you do not wish
                      to be bound by this agreement, do not indicate your
                      acceptance, and do not use our site or install the
                      extension/product.
                    </p>
                    <p className="p-terms">
                      For more details about how we collect, store, share and
                      use information collected, please visit our privacy policy
                      (the " Privacy Policy").
                    </p>
                    <h3 className="font-bold text-xl">Acceptance of Terms</h3>
                    <p className="p-terms">
                      The Site may contain links to third-party sites and/or
                      content (collectively, “Third-Party Content”). Any use by
                      you of Third-Party Content that is available via the
                      Extension/Product is subject to the terms and conditions
                      of any such Third-Party Content. You acknowledge that we
                      have no control over and assume no responsibility for
                      Third-Party Content. You assume all responsibility and
                      risk in connection with your use of Third-Party Content
                      and we hereby disclaim any and all liability to you or any
                      third party in connection thereto. We have no obligation
                      to examine, review or monitor Third Party Content and are
                      not responsible for the accuracy, completeness,
                      appropriateness, or legality of Third-Party Content. You
                      acknowledge and agree that certain Third-Party Content may
                      cease to be available to you from the Software at our sole
                      discretion and without notification to you.
                    </p>
                    <p className="p-terms">
                      This EULA and the privacy policy (together, this "
                      Agreement ") govern your use of our product. You may not
                      use our products if (a) you are not of legal age to form a
                      binding contract with us, or (b) you are a person barred
                      from receiving or using the product under the laws of the
                      United States or other countries, including the country in
                      which you are resident or from which you use the product.
                    </p>
                    <p className="p-terms">This Agreement is a legally binding agreement.</p>
                    <p className="p-terms">
                      By using and/or visiting Jukebucks you acknowledge that
                      we will not be liable for any damages, claims, or other
                      liability arising from and/or related use of Web sites
                      owned by third parties.
                    </p>
                    <h5>Age Requirement</h5>
                    <p className="p-terms">
                      You must be 13 years of age or older to install or use our
                      Services.
                    </p>
                    <h5>License & Use Restrictions</h5>
                    <p className="p-terms">
                      Subject to your compliance with these Terms, we hereby
                      grant you a limited, personal, non-transferable,
                      non-sublicensable, license (NOT SOLD) to use Jukebucks on
                      applicable devices that are under your control, provided
                      that you will fully comply with the terms and conditions
                      of these Terms and our Privacy Policy. You may not do any
                      of the following:
                    </p>
                    <ol className="ml-4 list-decimal">
                      <li className="p-terms">
                        Interfere with the security of the product in some way.
                      </li>
                      <li className="p-terms">
                        Use the product in a way that violates or disrupts the
                        services it provides.
                      </li>
                      <li className="p-terms">
                        Make any use of a product that violates the legal rights
                        of any user.
                      </li>
                      <li className="p-terms">Use the product illegally.</li>
                      <li className="p-terms">
                        Use the product in violation of applicable law or
                        regulation.
                      </li>
                    </ol>
                    <p className="p-terms">
                      We reserve the right at our sole discretion to suspend,
                      remove, or disable your access to Jukebucks at any time
                      and without notice. In no event, we will be liable for the
                      suspension, removal of, or disabling of your access or to
                      any feature available therein.
                    </p>
                    <h5>Un-install</h5>
                    <p className="p-terms">
                      You can easily uninstall Jukebucks by following the
                      instructions here.
                    </p>
                    <h5>Linked Content</h5>
                    <p className="p-terms">
                      Some of the content displayed or linked to by us is
                      developed by people over whom we exercise no control and
                      we cannot and do not screen the sites before including
                      them in the Site and/or Product. We cannot guarantee that
                      any content will not contain unintended or objectionable
                      content and assume no responsibility for the content of
                      any site included in the Site and/or Product, and we do
                      not assume responsibility for the goods, services, or
                      information provided by such sites.
                    </p>
                    <h5>Personal Use Only</h5>
                    <p className="p-terms">
                      Our Services are made available for your personal,
                      non-commercial use only. You may not take the Site’s
                      and/or Product’s different content and display them or
                      mirror any home page or results pages on your website or
                      other product.
                    </p>
                    <h5>Termination</h5>
                    <p className="p-terms">
                      We may at any time, with or without notice, terminate the
                      Agreement or block or disable your access or use of our
                      Product or Service if:
                    </p>
                    <ul className="mx-4 list-disc">
                      <li className="p-terms">
                        We believe that you have breached any provision of the
                        Agreement (or have acted in a manner that shows that you
                        do not intend to or are unable to comply with the
                        provisions of the Agreement).
                      </li>
                      <li className="p-terms">
                        We are required to do so by law (for example, where the
                        provision of the Product or Service to you is, or
                        becomes, unlawful).
                      </li>
                      <li className="p-terms">
                        The provision of the Product or Service to you by us is,
                        in our opinion, no longer commercially viable.
                      </li>
                      <li className="p-terms">
                        We believe that your use of the Product or Service may
                        infringe or violate the rights of a third party or
                        subject the Licensor to civil or criminal liability.
                      </li>
                    </ul>
                    <p className="p-terms">
                      All of the provisions of this Agreement which, by their
                      nature, are intended to survive termination hereof
                      (including, without limitation, all provisions relating to
                      indemnification, disclaimer of warranties,
                      indemnification, intellectual property rights, limitation
                      of liability, applicable law, jurisdiction and venue,
                      class actions and general matters), shall do so.
                    </p>
                    <h5>Copyright</h5>
                    <p className="p-terms">
                      “Intellectual Property”– including any and all trade
                      secrets, patents, copyrights, trademarks, service marks,
                      URLs, trade dress, brand features, know-how, moral rights,
                      contract rights, code (executable, source, and other) and
                      similar rights of any type under the laws of any
                      applicable governmental authority, or international
                      treaty, including, without limitation, all applications
                      and registrations relating to any of the foregoing.
                      Intellectual Property shall mean all intellectual property
                      whether registered or not and whether reduced into
                      practice or not.
                    </p>
                    <p className="p-terms">
                      All content on the product, including text documents
                      graphics software, etc., as well as trademarks, and logos,
                      are solely owned by us or licensed to us. We have the
                      right to edit, delete, distort, modify or move user
                      content without notice for any reason at any time.
                      Additionally, we have the right to refuse to transmit,
                      email, post, or broadcast any content without notice for
                      any reason at any time.
                    </p>
                    <h5>Indemnification</h5>
                    <p className="p-terms">
                      You agree to indemnify and to hold us ("Indemnifying Party
                      ") harmless, and indemnify us from and against any
                      expenses (including attorney fees) arising from claims of
                      third parties made against or incurred by the Indemnified
                      Party as a result of negligence, misrepresentation, error
                      or omission on the part of the Indemnifying Party or any
                      employee, agent or representative of the Indemnifying
                      Party, or any breach of this Agreement by the Indemnifying
                      Party, regardless of whether such claims were foreseeable
                      by the Indemnified Party. The Indemnified Party will have
                      the right to participate, at its expense, in the defense
                      of any claim covered hereunder with counsel of its own
                      choosing. The Indemnified Party shall give the
                      Indemnifying Party prompt notice of any such claim and
                      shall reasonably cooperate with the Indemnifying Party and
                      its counsel in the defense of such claim.
                    </p>
                    <h5>WARRANTIES</h5>
                    <p className="p-terms">
                      WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING
                      BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY AND
                      FITNESS FOR A PARTICULAR PURPOSE, AND EXCEPT AS EXPLICITLY
                      SET FORTH IN THIS AGREEMENT, ALL CONTENT ON THE SITE IS
                      PROVIDED "AS-IS ". WE DO NOT WARRANT THAT THE LICENSED
                      PRODUCT IS ERROR-FREE OR FREE FROM INTERRUPTIONS OR OTHER
                      FAILURES OR THAT THE PRODUCT WILL MEET THE DISTRIBUTOR'S
                      OR THE END CUSTOMER'S REQUIREMENTS.
                    </p>
                    <p className="p-terms">
                      IN NO EVENT WILL LICENSOR, OR ANY OF ITS AFFILIATES, OR
                      ANY OF ITS OR THEIR RESPECTIVE OFFICERS, DIRECTORS,
                      EMPLOYEES, AGENTS, REPRESENTATIVES, PARTNERS, MEMBERS,
                      ADVERTISERS, INFORMATION PROVIDERS OR LICENSORS BE LIABLE
                      FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
                      CONSEQUENTIAL, PUNITIVE OR OTHER DAMAGES (REGARDLESS OF
                      THE FORM OF ACTION), OR FOR ANY LOSS OF PROFITS, REVENUES,
                      BUSINESS OPPORTUNITIES, DATA OR USE OF SYSTEMS, ARISING
                      OUT OF (i) USE OF THE PRODUCT BY ANY PERSON, INCLUDING BUT
                      NOT LIMITED TO, ANY DAMAGE CAUSED BY ANY RELIANCE ON, OR
                      ANY DELAYS, INACCURACIES, DEFECTS, MALFUNCTIONS, ERRORS OR
                      OMISSIONS IN, ANY INFORMATION, CONTENT OR SOFTWARE
                      ACCESSED VIA THE PRODUCT, OR (ii) ANY USE OR INABILITY TO
                      USE THE PRODUCT FOR WHATEVER REASON, INCLUDING BUT NOT
                      LIMITED TO COMMUNICATION FAILURE OR ANY OTHER FAILURE WITH
                      TRANSMISSION OR DELIVERY OF ANY INFORMATION ACCESSED
                      THROUGH THE PRODUCT. SOME JURISDICTIONS DO NOT ALLOW THESE
                      LIMITATIONS OR EXCLUSIONS SO THEY MAY NOT APPLY TO YOU.
                    </p>
                    <h5>Limitation of Liability</h5>
                    <p className="p-terms">
                      YOU UNDERSTAND AND AGREE THAT THE WEBSITE, PRODUCT, AND
                      EXTENSION ARE PROVIDED ON AN "AS IS "AND "AS AVAILABLE"
                      BASIS. YOU EXPRESSLY AGREE THAT THE USE OF THE WEBSITE,
                      PRODUCT, AND EXTENSION IS AT YOUR SOLE RISK. TO THE
                      FULLEST EXTENT PERMISSIBLE UNDER THE APPLICABLE LAW, THE
                      LICENSOR AND ITS AFFILIATES DISCLAIM ALL WARRANTIES OF ANY
                      KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO,
                      WARRANTIES OF TITLE AND IMPLIED WARRANTIES OF
                      MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
                      WITHOUT LIMITING THE FOREGOING, NEITHER LICENSOR NOR ANY
                      OF ITS AFFILIATES, NOR ANY OF THEIR OFFICERS, DIRECTORS,
                      LICENSORS, EMPLOYEES OR REPRESENTATIVES REPRESENT OR
                      WARRANT (i) THAT THE WEBSITE, PRODUCT, AND EXTENSION,
                      INCLUDING THEIR CONTENT, WILL MEET YOUR REQUIREMENTS OR BE
                      ACCURATE, COMPLETE, RELIABLE, OR ERROR-FREE; (ii) THAT THE
                      WEBSITE, PRODUCT, AND EXTENSION WILL ALWAYS BE AVAILABLE
                      OR WILL BE UNINTERRUPTED, ACCESSIBLE, TIMELY, OR SECURE;
                      (iii) THAT ANY DEFECTS WILL BE CORRECTED, OR THAT THE
                      WEBSITE, PRODUCT AND EXTENSION WILL BE FREE FROM VIRUSES,
                      "WORMS," TROJAN HORSES "OR OTHER HARMFUL PROPERTIES; (iv)
                      THE ACCURACY, RELIABILITY, TIMELINESS, OR COMPLETENESS OF
                      ANY REVIEW, RECOMMENDATION, OR OTHER MATERIAL PUBLISHED OR
                      ACCESSIBLE ON OR THROUGH THE PRODUCT; (v) ANY IMPLIED
                      WARRANTY ARISING FROM COURSE OF DEALING OR USAGE OF TRADE;
                      AND (vi) THAT THE WEBSITE, PRODUCT, AND EXTENSION IS
                      NON-INFRINGING. LICENSOR AND ITS AFFILIATES HEREBY
                      DISCLAIM, AND YOU HEREBY WAIVE AND RELEASE LICENSOR AND
                      ITS AFFILIATES FROM, ANY AND ALL OBLIGATIONS, LIABILITIES,
                      RIGHTS, CLAIMS, OR REMEDIES IN TORT ARISING OUT OF OR IN
                      CONNECTION WITH THIS AGREEMENT OR THE WEBSITE, PRODUCT,
                      AND EXTENSION, WHETHER OR NOT ARISING FROM THE NEGLIGENCE
                      (ACTIVE, PASSIVE OR IMPUTED) OF THE LICENSOR OR ANY OF ITS
                      AFFILIATES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION
                      OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT
                      APPLY TO YOU. YOU MAY ALSO HAVE OTHER LEGAL RIGHTS, WHICH
                      VARY FROM JURISDICTION TO JURISDICTION.
                    </p>
                    <h5>Governing Law & Jurisdiction</h5>
                    <p className="p-terms">
                      THIS AGREEMENT SHALL BE CONSTRUED AND ENFORCED IN
                      ACCORDANCE WITH THE LAWS OF THE STATE OF United States,
                      WITHOUT REFERENCE TO ITS CONFLICTS OF LAW PRINCIPLES. The
                      courts located in Berkeley, CA shall have
                      exclusive jurisdiction to adjudicate any dispute arising
                      out of this Agreement, and each party hereby expressly
                      consents to the personal jurisdiction of such courts. A
                      determination that any term of this Agreement is invalid
                      or unenforceable shall not affect the other terms thereof.
                      Section headings are for convenience of reference only and
                      shall not affect the interpretation of this Agreement.
                    </p>
                    <h5>Miscellaneous</h5>
                    <p className="p-terms">
                      Nothing in this Agreement creates any agency, employment,
                      joint venture, or partnership relationship between you and
                      us. Except as may be expressly stated in these Terms,
                      these Terms constitute the entire agreement between us and
                      you pertaining to the subject matter hereof. We may assign
                      our rights and obligations under these Terms and the
                      Privacy Policy. You may not assign your rights and
                      obligations hereunder without our prior written consent.
                    </p>
                    <p className="p-terms">
                      YOU AGREE THAT YOUR CONTINUED USE OF THE WEBSITE, PRODUCT,
                      AND EXTENSION, FOLLOWING THE POSTING OF ANY CHANGES TO
                      THIS AGREEMENT AND AFTER THE CHANGES TAKE EFFECT WILL
                      CONSTITUTE YOUR ACCEPTANCE OF SUCH CHANGES. IF YOU DO NOT
                      AGREE TO FUTURE CHANGES TO THIS AGREEMENT, STOP USING THE
                      SITE AND OUR PRODUCTS AFTER THE EFFECTIVE DATE OF SUCH
                      CHANGES AND UNINSTALL THE PRODUCT.
                    </p>
                    <p className="p-terms">
                      We are constantly innovating in order to provide the best
                      possible experience for our users. You acknowledge and
                      agree that the form, features, or nature of the product we
                      provide may change from time to time without prior notice
                      to you. We may also cease or discontinue providing the
                      product or upgrades at any time.
                    </p>
                    <p className="p-terms">
                      For details about our data collection and data use
                      practices, please see our Privacy Policy. As explained in
                      the Privacy Policy, we may collect and store information
                      from your use or web sessions which may be used to display
                      targeted advertising.
                    </p>
                    <h5>Contact Us</h5>
                    <p className="p-terms">
                      If you have any questions, complaints, and/or claims, you
                      may contact us at: peter@jukebucks.org
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </Aux>
    );
  }
}

export default Terms;
